const usernameKey = "jira_username";
const passwordKey = "jira_password";

export const storeCredentials = (username: string, password: string) => {
	localStorage.setItem(usernameKey, username);
	localStorage.setItem(passwordKey, password);
};
export const loadCredentials = () => {
	return [localStorage.getItem(usernameKey), localStorage.getItem(passwordKey)];
};
export const clearCredentials = () => {
	localStorage.removeItem(usernameKey);
	localStorage.removeItem(passwordKey);
};
