import React from "react";
import ReactDOM from "react-dom";
import "./style.scss";

import axios from "axios";
import ReactLoading from "react-loading";
import moment from "moment";
import { getTimekeeperTimesheet, putTimekeeperData } from "./Timekeeper";
import { isJiraTimecode } from "./Jira/constants";
import { timecodeIdToString } from "./Timekeeper/constants";
import {
	getStartAndEndOfWeek,
	getNextWeek,
	getPreviousWeek,
	getWeekNumber,
} from "./Utils/dateUtils";
import { parse } from "./timesheetComparer";
import { ParseResult, TimecodePutRequest } from "./types";
import {
	storeCredentials,
	loadCredentials,
	clearCredentials,
} from "./Utils/localstorageUtils";

require("dotenv").config();

import {
	isAuthenticated,
	authenticate,
	getEmployeeIdFromToken,
} from "./Timekeeper/auth";

interface State {
	fromDate: string;
	toDate: string;
	jiraUsername: string;
	jiraPassword: string;
	rememberMe: boolean;
	parseResult: ParseResult;
	loading: boolean;
}
const verbose = false;

class AppSettings extends React.Component<{}, State> {
	constructor(props) {
		super(props);

		const [jiraUsername, jiraPassword] = loadCredentials();
		const hasCredentials = !!jiraUsername && !!jiraPassword;
		const [startOfWeek, endOfWeek] = getStartAndEndOfWeek();

		this.state = {
			fromDate: startOfWeek,
			toDate: endOfWeek,
			jiraUsername: jiraUsername,
			jiraPassword: jiraPassword,
			rememberMe: hasCredentials,
			parseResult: null,
			loading: hasCredentials,
		};

		if (hasCredentials) {
			this.getTimeSheets(startOfWeek, endOfWeek);
		}
	}
	render() {
		const {
			fromDate,
			toDate,
			jiraPassword,
			jiraUsername,
			loading,
			rememberMe,
		} = this.state;
		const getTimesheetDisabled = !jiraPassword;

		return (
			<div className="container">
				<h1>Jira to Timekeeper sync</h1>
				<fieldset>
					<legend>Tidspunkt:</legend>
					<div>
						<label>Fra: </label>
						<input
							type="text"
							value={fromDate}
							onChange={this.onInputChange("fromDate")}
						/>
					</div>
					<div>
						<label>Til: </label>
						<input
							type="text"
							value={toDate}
							onChange={this.onInputChange("toDate")}
						/>
					</div>
					<div className="mt-2">
						<button
							className="mr-2"
							onClick={(event) => this.onPreviousWeekClick(event)}
						>
							{"<<"}
						</button>
						<span>Uke: {getWeekNumber(fromDate)}</span>
						<button
							className="ml-2"
							onClick={(event) => this.onNextWeekClick(event)}
						>
							{">>"}
						</button>
					</div>
				</fieldset>
				<fieldset>
					<legend>Autentisering:</legend>
					<h5 className="my-2">Jira</h5>
					<form onSubmit={(event) => this.onGetTimesheetsClick(event)}>
						<div>
							<label>Brukernavn: </label>
							<input
								type="text"
								value={jiraUsername}
								onChange={this.onInputChange("jiraUsername")}
							/>
						</div>
						<div>
							<label>Passord: </label>
							<input
								type="password"
								value={jiraPassword}
								onChange={this.onInputChange("jiraPassword")}
							/>
						</div>
						<div>
							<label>
								<input
									name="remember-me"
									type="checkbox"
									checked={rememberMe}
									onChange={(event) => this.handleRememberMeChanged(event)}
								/>
								Husk meg (lagre jira credentials i local storage)
							</label>
						</div>
						<button type="submit" style={{ display: "none" }}></button>
					</form>
				</fieldset>
				<div className="mt-4">
					<button
						className="w-100 p-4"
						onClick={(event) => this.onGetTimesheetsClick(event)}
						disabled={getTimesheetDisabled}
					>
						Get Timesheets
					</button>
				</div>
				<div className="row">
					<div className="w-50">
						<div>
							<h2>Jira:</h2>
							<div>{this.renderJiraResult()}</div>
						</div>
						<div>
							<h3>Oppdateringer i timekeeper</h3>
							{this.renderMissmatch()}
						</div>
					</div>
					<div className="w-50">
						<h2>Timekeeper:</h2>
						<div>{this.renderTimekeeperResult()}</div>
					</div>
				</div>
				{loading ? (
					<div className="spinner-wrapper">
						<ReactLoading
							type="spin"
							className="spinner"
							height="100px"
							width="100px"
						/>
					</div>
				) : null}
			</div>
		);
	}

	renderJiraResult() {
		const { parseResult } = this.state;

		if (!parseResult) {
			return null;
		}

		const daysMap = parseResult.days;

		let result = [];
		for (const date in daysMap) {
			if (daysMap.hasOwnProperty(date)) {
				const day = daysMap[date];

				result.push(<h3 className="mb-2">{moment(date).format("dddd")}</h3>);

				for (const timecodeId in day.timecodes) {
					if (day.timecodes.hasOwnProperty(timecodeId)) {
						const timecode = day.timecodes[timecodeId];
						const correct = timecode.jiraHours === timecode.timekeeperHours;

						if (verbose) {
							result.push(
								<div>
									<h4>{timecodeIdToString(timecodeId)}</h4>
								</div>
							);
							result.push(<div>TK: {timecode.timekeeperHours}</div>);
							result.push(<div>JIRA: {timecode.jiraHours}</div>);
							result.push(
								<div style={{ marginLeft: "1rem" }}>
									<div>JIRA Keys: </div>
									{timecode.jiraKeys.map((key) => {
										return <div key={key}>{key}</div>;
									})}
								</div>
							);
						} else {
							if (correct) {
								result.push(
									<div style={{ color: "green" }}>
										{timecodeIdToString(timecodeId) +
											": " +
											timecode.timekeeperHours}
									</div>
								);
							} else {
								result.push(
									<div style={{ color: "red" }}>
										{timecodeIdToString(timecodeId) +
											" (jira: " +
											timecode.jiraHours +
											" tk: " +
											timecode.timekeeperHours}
										)
									</div>
								);
							}
						}
					}
				}
				result.push(<div></div>);
			}
		}
		return result;
	}

	renderMissmatch() {
		const { parseResult } = this.state;
		let moreThanOneNonLocked = false;

		if (!parseResult) {
			return null;
		}

		const daysMap = parseResult.days;
		let result = [];
		for (const date in daysMap) {
			if (daysMap.hasOwnProperty(date)) {
				const day = daysMap[date];
				for (const timecodeId in day.timecodes) {
					if (day.timecodes.hasOwnProperty(timecodeId)) {
						const timecode = day.timecodes[timecodeId];
						const correct = timecode.jiraHours === timecode.timekeeperHours;

						if (isJiraTimecode(timecode.timecodeId) && !correct) {
							result.push(
								<div style={{ color: day.locked ? "red" : null }}>
									{moment(date).format("dddd") +
										": " +
										timecodeIdToString(timecodeId) +
										" " +
										timecode.timekeeperHours +
										" => " +
										timecode.jiraHours}
								</div>
							);
							moreThanOneNonLocked = moreThanOneNonLocked || !day.locked;
						}
					}
				}
			}
		}

		if (result.length === 0) {
			result.push(<div>Ingen forskjell i timemene i jira og timekeeper.</div>);
		} else if (!moreThanOneNonLocked) {
			result.push(<div>Kun forskjell på dager som alt er låst</div>);
		} else {
			result.push(
				<button
					className="mt-2 p-4"
					onClick={(event) => {
						this.onPutTimekeeperClick(event);
					}}
				>
					Oppdater timekeeper
				</button>
			);
		}
		return result;
	}

	renderTimekeeperResult() {
		const { parseResult } = this.state;

		if (!parseResult) {
			return null;
		}

		const daysMap = parseResult.days;

		let result = [];
		for (const date in daysMap) {
			if (daysMap.hasOwnProperty(date)) {
				const day = daysMap[date];

				result.push(<h3 className="mb-2">{moment(date).format("dddd")}</h3>);

				for (const timecodeId in day.timecodes) {
					if (day.timecodes.hasOwnProperty(timecodeId)) {
						const timecode = day.timecodes[timecodeId];
						if (timecode.timekeeperHours !== 0) {
							result.push(
								<div
									style={{
										color: isJiraTimecode(timecode.timecodeId) ? "" : "red",
									}}
								>
									{timecodeIdToString(timecodeId) +
										": " +
										timecode.timekeeperHours}
								</div>
							);
						}
					}
				}
				result.push(<div></div>);
			}
		}
		return result;
	}

	onNextWeekClick(event?) {
		event?.stopPropagation();
		event?.preventDefault();

		const { fromDate } = this.state;
		const [from, to] = getNextWeek(fromDate);
		this.setState({
			fromDate: from,
			toDate: to,
			loading: true,
		});
		this.getTimeSheets(from, to);
	}

	onPreviousWeekClick(event?) {
		event?.stopPropagation();
		event?.preventDefault();

		const { fromDate } = this.state;
		const [from, to] = getPreviousWeek(fromDate);
		this.setState({
			fromDate: from,
			toDate: to,
			loading: true,
		});
		this.getTimeSheets(from, to);
	}

	onInputChange(field: string) {
		return (event) => {
			const value = event.target.value;
			this.setState({
				[field]: value,
			} as any);
		};
	}

	handleRememberMeChanged(event?) {
		const newRememberMe = event.target.checked;
		if (!newRememberMe) {
			clearCredentials();
		}
		this.setState({ rememberMe: newRememberMe });
	}

	async onGetTimesheetsClick(event?) {
		event?.stopPropagation();
		event?.preventDefault();

		const { rememberMe, jiraUsername, jiraPassword, fromDate, toDate } =
			this.state;
		if (rememberMe) {
			storeCredentials(jiraUsername, jiraPassword);
		}

		this.setState({ loading: true });
		await this.getTimeSheets(fromDate, toDate);
	}

	async getTimeSheets(fromDate: string, toDate: string) {
		const { jiraUsername, jiraPassword } = this.state;
		const jiraResponse = await this.getJiraTimesheet(
			fromDate,
			toDate,
			jiraUsername,
			jiraPassword
		);

		const timekeeperResponse = await getTimekeeperTimesheet(fromDate, toDate);

		if (timekeeperResponse && jiraResponse) {
			const parseResult = parse(timekeeperResponse.data, jiraResponse.data);
			this.setState({
				parseResult,
				loading: false,
			});
		} else {
			this.setState({ loading: false });
		}
	}

	async onPutTimekeeperClick(event) {
		event?.stopPropagation();
		event?.preventDefault();

		const { parseResult, fromDate, toDate } = this.state;
		const daysMap = parseResult.days;

		this.setState({ loading: true });

		for (const date in daysMap) {
			if (daysMap.hasOwnProperty(date)) {
				const day = daysMap[date];
				for (const timecodeId in day.timecodes) {
					if (day.timecodes.hasOwnProperty(timecodeId)) {
						const timecode = day.timecodes[timecodeId];
						const correct = timecode.jiraHours === timecode.timekeeperHours;
						if (
							isJiraTimecode(timecode.timecodeId) &&
							!correct &&
							!day.locked
						) {
							const data: TimecodePutRequest = {
								employeeId: getEmployeeIdFromToken(),
								hours: timecode.jiraHours,
								timecodeId: parseInt(timecodeId, 10),
								date: moment(day.date).format("YYYY-MM-DDT00:00:00"),
								comment: "",
								billable: null,
							};
							console.log("Updating", data);
							await putTimekeeperData(data);
						}
					}
				}
			}
		}

		this.getTimeSheets(fromDate, toDate);
		this.setState({ loading: false });
	}

	async getJiraTimesheet(
		fromDate: string,
		toDate: string,
		jiraUsername: string,
		jiraPassword: string
	) {
		try {
			const response = await axios.post(".netlify/functions/jira-timer", {
				fromDate,
				toDate,
				username: jiraUsername,
				password: jiraPassword,
			});
			if (response && response.status !== 200) {
				alert(
					`Unable to get jira timesheet, Status: ${
						response.status + response.statusText
					}`
				);
				return null;
			}
			return response;
		} catch (error) {
			alert(`Unable to get jira timesheet, ${error}`);
			return null;
		}
	}
}

if (!isAuthenticated()) {
	authenticate();
} else {
	ReactDOM.render(<AppSettings />, document.getElementById("root"));
}
