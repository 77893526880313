import moment from "moment";

export const getStartAndEndOfWeek = (date?: Date): [string, string] => {
	return [toDateString(getStartOfWeek(date)), toDateString(getEndOfWeek(date))];
};
export const getNextWeek = (date: Date | string): [string, string] => {
	var dayInNextWeek = moment(date).add(7, "days").toDate();
	var startOfWeek = getStartOfWeek(dayInNextWeek);
	var endOfWeek = getEndOfWeek(dayInNextWeek);
	return [toDateString(startOfWeek), toDateString(endOfWeek)];
};
export const getPreviousWeek = (date: Date | string): [string, string] => {
	var dayInNextWeek = moment(date).add(-7, "days").toDate();
	var startOfWeek = getStartOfWeek(dayInNextWeek);
	var endOfWeek = getEndOfWeek(dayInNextWeek);
	return [toDateString(startOfWeek), toDateString(endOfWeek)];
};

export const getWeekNumber = (date: Date | moment.Moment | string) => {
	return moment(date).week();
};

export const toSortableDateString = (date: Date | moment.Moment) => {
	return moment(date).format("YYYYMMDD");
};

function getStartOfWeek(d?: Date) {
	d = d || new Date();
	var day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

	return moment(new Date(d.setDate(diff)))
		.hour(0)
		.minute(0)
		.second(0)
		.millisecond(0)
		.toDate();
}

function getEndOfWeek(d?: Date) {
	var startOfWeek = getStartOfWeek(d);

	return moment(new Date(startOfWeek.setDate(startOfWeek.getDate() + 6)))
		.hour(23)
		.minute(59)
		.second(59)
		.millisecond(999)
		.toDate();
}

function toDateString(date: Date | moment.Moment) {
	return moment(date).format("YYYY-MM-DD");
}
