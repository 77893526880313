export const timecodeIdToString = (timecodeId: string) => {
	const map = {
		"35": "Rekrutteringsaktiviteter og intervjuer",
		"129": "Kompetansebygging",
		"1786": "Frivillig kompetansebygging",
		"1001498": "Nytt gjennomføringssystem",
		"1000752": "PAS Eksamen forvaltning",
		"1000890": "PAS Prøver forvaltning",
		"1000854": "IDPF Påloggingssystemet Forvaltning",
		"1002326": "Systemoversikten",
	};
	let timecode = map[timecodeId];
	if (!timecode) {
		console.error(`Missing timecode mapping for ${timecodeId}`);
		timecode = "Ukjent";
	}
	return timecode;
};
