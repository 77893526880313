import moment from "moment";
import { ParseResult, Responses, DaysMap, Day, Timecode } from "./types";

import { jiraIssueToTimecodeId } from "./Jira/constants";
import { toSortableDateString } from "./Utils/dateUtils";

export const parse = (
	timekeeperResponse: Responses.Timekeeper,
	jiraResponse: Responses.Jira
): ParseResult => {
	const lockDate = moment(timekeeperResponse.timesheetLockDate);
	const daysMap: DaysMap = {};

	jiraResponse.worklog.forEach((jiraEntry) => {
		const jiraKey = jiraEntry.key;
		const timecodeId = jiraIssueToTimecodeId(jiraEntry);

		if (!timecodeId) {
			throw `Unable to find timekeeper map for jirakey ${jiraKey}`;
		}

		jiraEntry.entries.forEach((entry) => {
			const date = toSortableDateString(entry.startDate);
			const timecodeDay = getOrAddTimecode(daysMap, date, timecodeId);

			timecodeDay.jiraHours += entry.timeSpent / 3600;
			timecodeDay.isJiraTimecode = true;

			const timekeeperJiraKeys = timecodeDay.jiraKeys;
			if (!contains(timekeeperJiraKeys, jiraKey)) {
				timekeeperJiraKeys.push(`${jiraKey} - ${jiraEntry.summary}`);
			}
		});
	});

	timekeeperResponse.timeEntries.forEach((entry) => {
		const timecodeId = entry.timecodeId;
		const date = toSortableDateString(entry.date);
		const timecodeDay = getOrAddTimecode(daysMap, date, timecodeId);
		const day = daysMap[date];
		if (day) {
			day.locked = moment(entry.date) <= lockDate;
		}

		if (timecodeDay) {
			timecodeDay.timekeeperHours += entry.hours;
		}
	});

	return {
		lockDate: lockDate,
		days: daysMap,
	};
};

function getOrAddTimecode(
	daysMap: DaysMap,
	dayString: string,
	timecodeId: number
): Timecode {
	const day = getOrAddDay(daysMap, dayString);
	day.timecodes[timecodeId] = day.timecodes[timecodeId] || {
		timecodeId: timecodeId,
		timekeeperHours: 0,
		jiraHours: 0,
		jiraKeys: [],
		isJiraTimecode: false,
	};
	return day.timecodes[timecodeId];
}

function getOrAddDay(daysMap: DaysMap, day: string): Day {
	daysMap[day] = daysMap[day] || {
		date: day,
		timecodes: {},
	};
	return daysMap[day];
}

function contains(array: string[], item: string): boolean {
	return array.indexOf(item) !== -1;
}
